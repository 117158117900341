import React from "react";
import { Col, Container, Row } from "reactstrap";

// sections for this page

function Impressum() {
  return (
    <div className="fullpage-content">
      <div className="cd-section bg-white" id="BasicText">
        <div className="">
          <div className="mt-5 mb-5">
            <Container>
              <Row>
                <Col className="mr-auto" md="8">
                  <h2 className="title">Impressum</h2>
                  <h4 className="">
                    VEMO Logistik GmbH
                    <br />
                    <br />
                    Wilhelm-Mauser-Str. 47
                    <br />
                    50827 Köln
                    <br />
                    Deutschland
                    <br />
                  </h4>
                  <p>
                    Telefon: 0221 98658988
                    <br />
                    Mail: kontakt@vemo-logistik.de
                    <br />
                    Geschäftsführung: Jonathan Kümmerle, Paul Schwarzelühr
                    <br />
                    Handelsregister: Amtsgericht Köln HRB 113547
                    <br />
                  </p>
                  <p>
                    Die Europäische Kommission stellt eine Plattform zur
                    Online-Streitbeilegung (OS) bereit, die Sie hier finden
                    https://ec.europa.eu/consumers/odr/. Zur Teilnahme an einem
                    Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle sind wir nicht verpflichtet
                    und nicht bereit.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Impressum;
