import React from "react";
// javascript library that creates a parrallax effect
// reactstrap components
// core components
import {Button, Col, Container, Row} from "reactstrap";

// sections for this page

function UlmenhofReferenz() {

    return (
        <>
            <div className="cd-section bg-primary spacing-top" id="BasicText">
                <div className="">
                    <div className="pb-5">
                        <Container>
                            <Row>
                                <Col
                                    className="mr-auto ml-auto bg-white rounded shadow pl-5 pr-5 py-4"
                                    md="9">
                                    <h1 className="h2 title">Ulmenhof</h1>
                                    <h2 className="h4">
                                        Der Ulmenhof könnte auch aus einem Bilderbuch sein. Zwischen grünen Weiden
                                        und Wäldern liegt er in einem idyllischen Seitental der Vulkaneifel. Zwei
                                        Familien bewirtschaften dort die Felder mit verschiedenen Gemüsesorten.
                                    </h2>
                                    <p>
                                        Daneben grasen Kühe, Ziegen sowie Hühner und eine hofeigene Käserei erinnert
                                        an einen kleinen Handwerksbetrieb. Die dort entstandenen Milchprodukte sowie
                                        Eier und Tiefkühlwaren verkauft der Hof dann an verschiedene Bioläden in
                                        Köln und Bon – wobei wir die Lieferung innerhalb der Städte übernehmen.
                                    </p>
                                    <p>
                                        Täglich bringt uns ein Fahrer des <a
                                        href={"https://ulmenhof-gbr.de/"}>Ulmenhof</a> die Produkte zu unserem
                                        VEMO-Standort und wir laden in unsere Lastenräder um. So spart sich das Team
                                        des Hofes die Fahrten durch den Stadtverkehr und kann wieder zurück aufs
                                        Feld – dahin, wo Wichtigeres wartet.
                                    </p>
                                    <p>
                                        Wir stellen die Lieferungen noch am selben Tag zu. Durch das Übernehmen der
                                        „Letzten Meile“ spart sich der Ulmenhof nicht nur Zeit, sondern schont auch
                                        die Umwelt, die sonst durch den Stop-and-Go-Verkehr in der Stadt belastet
                                        würde. Die umweltbewussten Bioläden sind genau die Zielgruppe, die dieses
                                        Engagement honoriert. So liefern wir ein weiteres Verkaufsargument für den
                                        Hof aus der Vulkaneifel.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="text-center">
                                    <Button
                                        color="white"
                                        href="/#Referenzen-Section"
                                        className="rounded-pill text-center btn-yellow font-weight-bold"
                                    >
                                        zurück zur Übersicht
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UlmenhofReferenz;