import React from "react";
// javascript library that creates a parrallax effect
// reactstrap components
// core components
import HeaderContainer from "../components/Headers/PresentationHeader.js";

// sections for this page
import Features from "./index-sections/Features";
import SupplyArea from "./index-sections/SupplyArea";
import Vision from "./index-sections/Vision";
import References from "./index-sections/References";
import ContactUs from "./index-sections/ContactUs";

function Index() {

    return (
        <>
            <HeaderContainer/>
            <Features/>
            <SupplyArea/>
            <Vision/>
            <ContactUs/>
            <References/>
        </>
    );
}

export default Index;