import React from "react";
// javascript library that creates a parrallax effect
// reactstrap components
// core components
import {Button, Col, Container, Row} from "reactstrap";

// sections for this page

function MemoReferenz() {
    return (
        <>
            <div className="cd-section bg-primary spacing-top" id="BasicText">
                <div className="">
                    <div className="pb-5">
                        <Container>
                            <Row>
                                <Col
                                    className="mr-auto ml-auto bg-white rounded shadow pl-5 pr-5 py-4"
                                    md="9">
                                    <h1 className="h2 title">memo AG</h1>
                                    <h2 className="h4">
                                        Hier gibt’s was zu schleppen. Die memo AG ist ein Versandhandelsunternehmen
                                        für ökologische, sozialverträgliche und qualitativ hochwertige Produkte und
                                        ein Experte in nachhaltiger Logistik.
                                    </h2>
                                    <p>
                                        So räumt das Unternehmen schon seit vielen Jahren eigentlich jeden
                                        relevanten Nachhaltigkeitspreis ab. Und wir wiederum räumen 30 Kilogramm
                                        schwere Pakete für das Unternehmen ab und liefern diese an Geschäftskunden
                                        aus.
                                    </p>
                                    <p>
                                        Die Ladungen sind eine Wucht und nicht für alle zu handhaben, aber wir haben
                                        zum Glück starke Männer und Frauen im Team. Und unser Bonner Lademeister,
                                        wie unser liebstes Fahrrad heißt, wuppt das sowieso. Außerdem ist memo ein
                                        Kunde, den wir wirklich sehr gerne in unserem Portfolio haben – nicht
                                        zuletzt, weil die Spezialisierung auf Büro- und Haushaltsbedarf für uns eine
                                        ganz neue Branche ist. Wir möchten schließlich zeigen, was wir alles können
                                        und das am liebsten mit einem Unternehmen, das unsere Werte teilt.
                                    </p>
                                    <p>
                                        Das Credo unseres Partners: Ein verantwortungsvoller Konsum soll in allen
                                        Lebensbereichen möglich sein. Weil memo das Thema Nachhaltigkeit umfassend
                                        von der Produktion bis zur Auslieferung denken möchte, sind Lastenfahrräder
                                        die perfekte Lösung für die innerstädtische Distribution. Denn:
                                        Versandhandel und Nachhaltigkeit müssen kein Widerspruch sein.
                                    </p>
                                    <p>
                                        In dieser Hinsicht ergab unser Kennenlernen ein perfektes Match. Seit
                                        einiger Zeit dürfen wir Pakete mit nachhaltigem Büro- und Haushaltsbedarf an
                                        die Geschäftskunden des Unternehmens ausliefern. Regelmäßig liefert uns ein
                                        Fahrer von memo fertig kommissionierte Pakte an unser Depot in Bonn,
                                        woraufhin wir uns direkt an die Distribution machen. Schnell und
                                        klimafreundlich geht’s zu den innerstädtischen Kund*innen des
                                        Versandhandelsunternehmens.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="text-center">
                                    <Button
                                        color="white"
                                        href="/#Referenzen-Section"
                                        className="rounded-pill text-center btn-yellow font-weight-bold"
                                    >
                                        zurück zur Übersicht
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MemoReferenz;