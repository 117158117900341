import React from "react";
// javascript library that creates a parrallax effect
// reactstrap components
// core components
import {Button, Col, Container, Row} from "reactstrap";

// sections for this page

function MomoReferenz() {

    return (
        <>
            <div className="cd-section bg-primary spacing-top" id="BasicText">
                <div className="">
                    <div className="pb-5">
                        <Container>
                            <Row>
                                <Col
                                    className="mr-auto ml-auto bg-white rounded shadow pl-5 pr-5 py-4"
                                    md="9">
                                    <h1 className="h2 title">Bioladen Momo</h1>
                                    <h2 className="h4">
                                        Mit ihm fing alles an: Der Bioladen Momo ist unser längster Partner und seit
                                        Herbst 2020 gemeinsam mit uns unterwegs.
                                    </h2>
                                    <p>
                                        Neben seiner Verkaufsfläche hat der Biolanden einen erfolgreichen
                                        Lieferdienst mit mehreren hundert Kund*innen im Bonner Stadtgebiet. Nach
                                        Liefertagen und Vierteln sortiert, hat der <a
                                        href="http://bioladen.com/" target="_blank" rel="noreferrer"
                                        className="link-primary">Bioladen
                                        Momo </a> feste
                                        Touren. Von
                                        diesen übernehmen wir einen großen Teil mit unserer umweltfreundlichen
                                        Lastenradflotte.
                                    </p>
                                    <p>
                                        Von Momo haben wir viel über Logistik und professionelle Abläufe gelernt.
                                        Kleine Pause von den gut geölten Prozessen gibt es, wenn unsere Fahrer*innen
                                        zwischen ihren Touren Bananen geschenkt bekommen oder wenn wir uns an
                                        Weihnachten über kleine Geschenke aus dem Bioladen freuen.
                                    </p>
                                    <p>
                                        Aber klar ist: Im Alltag wird malocht. Von Montag bis Freitag starten die
                                        Touren am VEMO-Standort in Bonn. Erster Stopp ist der Bioladen selbst, bei
                                        dem wir mit bis zu drei Lastenrädern die fertig kommissionierten Kisten
                                        abholen. Nach dem Einladen fahren unsere Fahrer direkt los zu den Kunden.
                                        Wir nehmen Pfand mit, sortieren und notieren diesen für die korrekte
                                        Abrechnung und beenden die Tour wieder beim Bioladen.
                                    </p>
                                    <p>
                                        Inzwischen sind wir gemeinsam so gut eingespielt, dass unser Team die Kunden
                                        und Prozesse genau kennt und die täglichen Touren zur Routine geworden sind.
                                        Dabei verbessern wir weiterhin unsere Flotte. So wächst nicht nur die
                                        Partnerschaft, sondern auch die Qualität Stück für Stück weiter.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="text-center">
                                    <Button
                                        color="white"
                                        href="/#Referenzen-Section"
                                        className="rounded-pill text-center btn-yellow font-weight-bold"
                                    >
                                        zurück zur Übersicht
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MomoReferenz;