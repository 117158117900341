import {useContext, useEffect, useRef} from "react";
import {NavContext} from "../context/NavContext";
import {useOnScreen} from "./useOnScreen";

export const useNav = navLinkId => {
    const ref = useRef(null);

    const {setActiveNavLinkId} = useContext(NavContext);

    const isOnScreen = useOnScreen(ref);

    useEffect(() => {
        if (isOnScreen && setActiveNavLinkId) {
            setActiveNavLinkId(navLinkId);
        }
    }, [isOnScreen, setActiveNavLinkId, navLinkId]);

    return ref;
};
