/*eslint-disable*/
import React from "react";
import {useNav} from "../../assets/customHooks/useNav";
import sky from "../../assets/img/himmel02.jpeg"
import skyline from "../../assets/img/skyline.png"
import LazyLoad from 'react-lazyload';
// reactstrap components
// core components
const HeaderContainer = () => {
    const PresentationHeaderRef = useNav('PresentationHeader');
    return (
        <section ref={PresentationHeaderRef} id='HeaderContainer'>
            <div className="page-header clear-filter">

                <div className="rellax-header rellax-header-sky" data-rellax-speed="-6">
                    <LazyLoad>
                        <div
                            className="page-header-image"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    sky +
                                    ")",
                            }}
                        />
                    </LazyLoad>
                </div>

                <div
                    className="rellax-header rellax-header-buildings"
                    data-rellax-speed="-3"
                >
                    <LazyLoad>
                        <div
                            className="page-header-image page-header-city"
                            style={{
                                backgroundImage: 'url(' + skyline + ')'
                            }}
                        />
                    </LazyLoad>
                </div>
                <div className="rellax-text-container rellax-text">
                    <h1 className="h1-seo mb-0" data-rellax-speed="-1">
                        Radlogistik
                    </h1>
                </div>
                <h2 className="h2 h3-description rellax-text" data-rellax-speed="-1">
                    Logistik der Zukunft
                </h2>
            </div>
        </section>
    );
}

export default HeaderContainer;