import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/basic.scss?v=1.5.0";
import {hydrate, render} from "react-dom";
// pages
import Index from "./views/index.js";

import Impressum from "./views/Impressum";
import DLSReferenz from "./views/DLS-referenz";
import MomoReferenz from "./views/Momo-referenz";
import MemoReferenz from "./views/memo-referenz";
import UlmenhofReferenz from "./views/Ulmenhof";
import ContentFrame from "./views/templates/ContentFrame";
import Datenschutz from "./views/Datenschutz";
// others


const App = () => (
    <BrowserRouter>
        <ContentFrame>
            <Routes>
                <Route path="*" element={<Navigate replace to="/"/>}/>
                <Route
                    path="/"
                    element={<Index/>}
                />
                <Route
                    path="/Impressum"
                    element={<Impressum/>}
                />
                <Route
                    path="/Datenschutz"
                    element={<Datenschutz/>}
                />
                <Route
                    path="/referenz/DLS-Baeckerei"
                    element={<DLSReferenz/>}
                />
                <Route
                    path="/referenz/Bioladen-Momo"
                    element={<MomoReferenz/>}
                />
                <Route
                    path="/referenz/Memo"
                    element={<MemoReferenz/>}
                />
                <Route
                    path="/referenz/Ulmenhof"
                    element={<UlmenhofReferenz/>}
                />
            </Routes>
        </ContentFrame>
    </BrowserRouter>

)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<App/>, rootElement);
} else {
    render(<App/>, rootElement);
}