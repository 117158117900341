import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { useNav } from "../../assets/customHooks/useNav";
import LazyLoad from "react-lazyload";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const fieldNameGdprAccepted = "gdprAccepted";
  const fieldNameName = "name";
  const fieldNameEmail = "email";
  const fieldNamePhone = "phone";
  const fieldNameMessage = "message";
  const ContactUsRef = useNav("ContactUs");

  const [mailSent, setMailSent] = useState(false);
  const [errorField, setErrorField] = useState(null);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!formData[fieldNameName]) {
      setError("Bitte gib einen Namen ein!");
      setErrorField(fieldNameName);
      return;
    }
    if (
      !formData[fieldNameEmail] ||
      !formData[fieldNameEmail]
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setError("Bitte gib eine valide E-Mail Adresse ein!");
      setErrorField(fieldNameEmail);
      return;
    }
    if (!formData[fieldNameGdprAccepted]) {
      setError("Bitte akzeptiere unsere Datenschutzvereinbarung!");
      setErrorField(fieldNameGdprAccepted);
      return;
    }
    // console.log("formdatat", formData);
    setLoading(true);
    emailjs
      .send(
        "service_c3q33xg",
        "template_gbrtjwe", // neue kontaktanfrage
        formData,
        "MEyapuSkMJLLc4dVa"
      )
      .then(() => {
        emailjs
          .send(
            "service_c3q33xg",
            "template_w86v0zm", // bestätigungsmail
            formData,
            "MEyapuSkMJLLc4dVa"
          )
          .then(
            (result) => {
              console.log(result);
              if (result.status === 200) {
                setMailSent(true);
                setError(false);
                setErrorField(null);
              } else {
                setError(result.text);
                setErrorField(null);
              }
              setLoading(false);
            },
            (error) => {
              console.log(error);
              setError(
                error.msg ||
                  "Es ist ein Fehler während dem abschicken passiert. Bitte probiere es später erneut oder sende uns deine Anfrage per E-Mail zu: kontakt@vemo-logistik.de"
              );
              setErrorField(null);
              setLoading(false);
            }
          );
      })
      .then(
        (result) => {
          console.log("res2", result);
        },
        (error) => {
          console.log(error);
          setError(
            error.msg ||
              "Es ist ein Fehler während dem abschicken passiert. Bitte probiere es später erneut oder sende uns deine Anfrage per E-Mail zu: kontakt@vemo-logistik.de"
          );
          setErrorField(null);
          setLoading(false);
        }
      );
  };
  const handleChange = (e, field) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleCheckboxChange = (e, field) => {
    const value = e.target.checked;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <section ref={ContactUsRef} id="Kontakt-Section">
      <div className="cd-section bg-white bg-half-blue" id="features">
        <div className="contactUs">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10" lg="8">
                <h2 className="title">Unterwegs in Köln und Bonn</h2>
                <h3 className="h4 description text-darkblue">
                  Für Deine Nachhaltige City-Logistik bieten wir Dir
                  <br />
                  unterschiedliche Leistungen an. Sprich uns gerne an!
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="12" lg="10">
                <Row className="justify-content-around pt-5">
                  <Col md="11" lg="12">
                    <Card className="card-plain bg-white shadow">
                      <CardBody className="p-3 p-md-4">
                        <Row className="justify-content-around">
                          <Col md="5" className="d-none d-md-inline p-0">
                            <figure className="img">
                              <LazyLoad height={365}>
                                <img
                                  alt="Paul"
                                  className="img card-image rounded shadow"
                                  src={require("assets/img/Paul.png")}
                                />
                              </LazyLoad>
                              <figcaption className="text-center mt-4 mb-4">
                                Paul, Gründer und verantwortlich für Operations.{" "}
                                <a
                                  className=""
                                  href="https://www.linkedin.com/in/paul-schwarzel%C3%BChr-46b01a187/"
                                >
                                  Zu LinkedIn
                                </a>
                              </figcaption>
                            </figure>
                            <figure className="img">
                              <LazyLoad height={365}>
                                <img
                                  alt="Max"
                                  className="img card-image rounded shadow"
                                  src={require("assets/img/Jonathan.png")}
                                />
                              </LazyLoad>
                              <figcaption className="text-center mt-4">
                                Jonathan, Gründer und verantwortlich für
                                Kooperationen.{" "}
                                <a
                                  className=""
                                  href="https://www.linkedin.com/in/jonathan-k%C3%BCmmerle/"
                                >
                                  Zu LinkedIn
                                </a>
                              </figcaption>
                            </figure>
                          </Col>
                          <Col md="6">
                            <Row>
                              <Col className="" md="12">
                                <h2 className="title">Jetzt kontaktieren</h2>
                                <p className="text-darkblu">
                                  Du hast Interesse oder Fragen zu unseren
                                  Leistungen. Schreib uns gerne eine Mail mit
                                  deiner Anfrage.
                                </p>
                              </Col>
                            </Row>
                            {!mailSent ? (
                              <>
                                <Form id="contact-form" action="#">
                                  <FormGroup>
                                    <TextField
                                      required
                                      fullWidth
                                      label="Dein Name"
                                      variant="standard"
                                      error={
                                        errorField === fieldNameName && !!error
                                      }
                                      helperText={
                                        errorField === fieldNameName && error
                                      }
                                      onChange={(e) =>
                                        handleChange(e, fieldNameName)
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <TextField
                                      required
                                      fullWidth
                                      label="Deine E-Mail"
                                      variant="standard"
                                      error={
                                        errorField === fieldNameEmail && !!error
                                      }
                                      helperText={
                                        errorField === fieldNameEmail && error
                                      }
                                      onChange={(e) =>
                                        handleChange(e, fieldNameEmail)
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <TextField
                                      fullWidth
                                      label="Deine Telefonummer"
                                      variant="standard"
                                      onChange={(e) =>
                                        handleChange(e, fieldNamePhone)
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <TextField
                                      fullWidth
                                      multiline
                                      rows={4}
                                      label="Deine Nachricht"
                                      variant="standard"
                                      error={
                                        errorField === fieldNameMessage &&
                                        !!error
                                      }
                                      helperText={
                                        errorField === fieldNameMessage && error
                                      }
                                      onChange={(e) =>
                                        handleChange(e, fieldNameMessage)
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              fieldNameGdprAccepted
                                            )
                                          }
                                          xs={{
                                            fontSize: "1.2rem",
                                          }}
                                        />
                                      }
                                      label="Ich akzeptiere die  Datenschutzbestimmungen der VEMO Logistik GmbH."
                                    />
                                    {errorField === fieldNameGdprAccepted &&
                                      error && (
                                        <Alert severity="error">{error}</Alert>
                                      )}
                                  </FormGroup>
                                  {error && !errorField && (
                                    <Stack sx={{ width: "100%" }} spacing={2}>
                                      <Alert severity="error">{error}</Alert>
                                    </Stack>
                                  )}
                                  <div className="submit text-center">
                                    <Button
                                      className={`btn-raised btn-round w-50 ${
                                        loading ? "btn-dark" : ""
                                      }`}
                                      color="primary"
                                      defaultValue="Contact Us"
                                      type="submit"
                                      onClick={(e) => handleFormSubmit(e)}
                                      disabled={loading}
                                    >
                                      {loading ? "sendet..." : "Senden"}
                                    </Button>
                                  </div>
                                </Form>
                              </>
                            ) : (
                              <Alert severity="success">
                                <AlertTitle>Vielen Dank!</AlertTitle>
                                Deine Anfrage wurde versendet und wird von uns
                                schnellstmöglich bearbeitet.
                              </Alert>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
