import React from "react";
import {Col, Container, Row} from "reactstrap";

// sections for this page

function Datenschutz() {
    return (
        <div className="fullpage-content">
            <div className="cd-section bg-white" id="BasicText">
                <div className="">
                    <div className="mt-5 mb-5">
                        <Container>
                            <Row>
                                <Col className="mr-auto" md="8">
                                    <h2 className="title">Datenschutz</h2>
                                    <p>
                                        Verantwortlicher für die Datenverarbeitung ist:<br/>

                                        VEMO Logistik GmbH<br/><br/>

                                        Wilhelm-Mauser-Str. 47<br/>
                                        50827 Köln<br/>
                                        Deutschland<br/>

                                        kontakt@vemo-logistik.de
                                    </p>
                                    <p>
                                        Wir freuen uns über Ihr Interesse an Vemo. Der Schutz Ihrer Privatsphäre ist für
                                        uns
                                        sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit
                                        Ihren
                                        Daten.
                                    </p>
                                    <h5> 1. Zugriffsdaten und Hosting</h5>

                                    <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei
                                        jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein
                                        sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei, Ihre
                                        IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den
                                        anfragenden
                                        Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.
                                        Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines
                                        störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots
                                        ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung
                                        unserer im
                                        Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer
                                        korrekten Darstellung unseres Angebots. Alle Zugriffsdaten werden spätestens
                                        sieben
                                        Tage nach Ende Ihres Seitenbesuchs gelöscht.</p>
                                    <p>
                                        Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für
                                        uns
                                        die Dienste zum Hosting und zur Darstellung der Webseite. Alle Daten, die im
                                        Rahmen
                                        der Nutzung dieser Webseite oder in dafür vorgesehenen Formularen im Onlineshop
                                        wie
                                        folgend beschrieben erhoben werden, werden auf seinen Servern verarbeitet. Eine
                                        Verarbeitung auf anderen Servern findet nur in dem hier erläuterten Rahmen
                                        statt.
                                        Die Server befinden sich in Deutschland und werden mit Ökostrom klimaneutral
                                        betrieben.</p>

                                    <h5>2. Datenerhebung und -verwendung zur Vertragsabwicklung, Kontaktaufnahme</h5>
                                    <p>
                                        Wir erheben personenbezogene Daten, bei einer Kontaktaufnahme mit uns (z.B. per
                                        Kontaktformular oder E-Mail) mitteilen. Pflichtfelder werden als solche
                                        gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
                                        Vertragsabwicklung,
                                        bzw. zur Bearbeitung Ihrer Kontaktaufnahme benötigen bzw. die Kontaktaufnahme
                                        nicht
                                        versenden können. Welche Daten erhoben werden, ist aus den jeweiligen
                                        Eingabeformularen ersichtlich.
                                        Wir verwenden die von ihnen mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b
                                        DSGVO
                                        zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Nach vollständiger
                                        Abwicklung
                                        des Vertrages werden Ihre Daten für die weitere Verarbeitung eingeschränkt und
                                        nach
                                        Ablauf etwaiger steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht,
                                        sofern
                                        Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben
                                        oder
                                        wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich
                                        erlaubt ist und über die wir Sie in dieser Erklärung informieren.
                                    </p>

                                    <h5> 3. E-Mail-Newsletter und Postwerbung</h5>

                                    E-Mail-Werbung mit Anmeldung zum Newsletter<br/>
                                    <p>
                                        Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür
                                        erforderlichen oder gesondert von Ihnen mitgeteilten Daten, um Ihnen regelmäßig
                                        unseren E-Mail-Newsletter aufgrund Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1
                                        lit.
                                        a DSGVO zuzusenden.
                                    </p>
                                    <p>
                                        Die Abmeldung vom Newsletter ist jederzeit möglich und kann entweder durch eine
                                        Nachricht an die unten beschriebene Kontaktmöglichkeit oder über einen dafür
                                        vorgesehenen Link im Newsletter erfolgen. Nach Abmeldung löschen wir Ihre
                                        E-Mail-Adresse aus der Empfängerliste, soweit Sie nicht ausdrücklich in eine
                                        weitere
                                        Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende
                                        Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in
                                        dieser Erklärung informieren.
                                        E-Mail-Werbung ohne Anmeldung zum Newsletter und Ihr Widerspruchsrecht
                                    </p><p>
                                    Wenn wir Ihre E-Mail-Adresse im Zusammenhang mit dem Verkauf einer Ware oder
                                    Dienstleistung erhalten und Sie dem nicht widersprochen haben, behalten wir uns vor,
                                    Ihnen auf Grundlage von § 7 Abs. 3 UWG regelmäßig Angebote zu ähnlichen Produkten,
                                    wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden. Dies dient
                                    der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten
                                    Interessen an einer werblichen Ansprache unserer Kunden.
                                    Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch eine Nachricht an
                                    die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link in
                                    der Werbemail widersprechen, ohne dass hierfür andere als die Übermittlungskosten
                                    nach den Basistarifen entstehen.
                                </p><p>
                                    Der Newsletter wird im Rahmen einer Verarbeitung in unserem Auftrag durch Mailchimp
                                    versendet, an den wir Ihre E-Mail-Adresse hierzu weitergeben.
                                    Dieser Dienstleister sitzt in den USA und ist zertifiziert unter dem EU-US-Privacy
                                    Shield. Aufgrund dieses Abkommens zwischen den USA und der Europäischen Kommission
                                    hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein angemessenes
                                    Datenschutzniveau festgestellt.
                                </p>
                                    <h5>4. Cookies und Webanalyse</h5>
                                    <p>
                                        Einsatz von Google (Universal) Analytics zur Webanalyse

                                        Zur Webseitenanalyse setzt diese Website Google (Universal) Analytics ein. Der
                                        Webanalysedienst ist ein Angebot der Google Ireland Limited, einer nach irischem
                                        Recht eingetragenen und betriebenen Gesellschaft mit Sitz in Gordon House,
                                        Barrow
                                        Street, Dublin 4, Irland (www.google.de). Dies dient der Wahrung unserer im
                                        Rahmen
                                        einer Interessensabwägung überwiegenden berechtigten Interessen an einer
                                        optimierten
                                        Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO. Google
                                        (Universal) Analytics verwendet Methoden, die eine Analyse der Benutzung der
                                        Website
                                        durch Sie ermöglichen, wie zum Beispiel Cookies. Die automatisch erhobenen
                                        Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
                                        Server
                                        von Google in den USA übertragen und dort gespeichert. Durch die Aktivierung der
                                        IP-Anonymisierung auf dieser Webseite wird dabei die IP-Adresse vor der
                                        Übermittlung
                                        innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen
                                        Vertragsstaaten
                                        des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in
                                        Ausnahmefällen
                                        wird die volle IP-Adresse an einen Server von Google in den USA übertragen und
                                        dort
                                        gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
                                        anonymisierte IP-Adresse wird grundsätzlich nicht mit anderen Daten von Google
                                        zusammengeführt. Nach Zweckfortfall und Ende des Einsatzes von Google Analytics
                                        durch uns werden die in diesem Zusammenhang erhobenen Daten gelöscht.
                                        Soweit Informationen auf Server von Google in den USA übertragen und dort
                                        gespeichert werden, ist die amerikanische Gesellschaft Google LLC unter dem
                                        EU-US-Privacy Shield zertifiziert. Ein aktuelles Zertifikat kann hier eingesehen
                                        werden. Aufgrund dieses Abkommens zwischen den USA und der Europäischen
                                        Kommission
                                        hat letztere für unter dem Privacy Shield zertifizierte Unternehmen ein
                                        angemessenes
                                        Datenschutzniveau festgestellt.
                                        Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
                                        Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                                        Verarbeitung
                                        dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
                                        verfügbare Browser-Plugin herunterladen und installieren:
                                        https://tools.google.com/dlpage/gaoptout?hl=de
                                        Alternativ zum Browser-Plugin können Sie diesen Link klicken, um die Erfassung
                                        durch
                                        Google Analytics auf dieser Website zukünftig zu verhindern. Dabei wird ein
                                        Opt-Out-Cookie auf Ihrem Endgerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie
                                        den
                                        Link erneut klicken.

                                        Mehr zu unseren Cookie-Richtlinien:
                                        https://www.himmel-un-aad.de/cookie-richtlinien/
                                    </p>

                                    <h5>5. Kontaktmöglichkeiten und Ihre Rechte</h5>
                                    <p>
                                        Als Betroffener haben Sie folgende Rechte:
                                        * gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über
                                        Ihre
                                        von uns verarbeiteten personenbezogenen Daten zu verlangen;
                                        * gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder
                                        Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu
                                        verlangen;
                                        * gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten
                                        personenbezogenen Daten zu verlangen, soweit nicht die weitere Verarbeitung
                                        * zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                                        * zur Erfüllung einer rechtlichen Verpflichtung;
                                        * aus Gründen des öffentlichen Interesses oder
                                        * zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                                        erforderlich
                                        ist;
                                        * gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer
                                        personenbezogenen Daten zu verlangen, soweit
                                        * die Richtigkeit der Daten von Ihnen bestritten wird;
                                        * die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
                                        * wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung,
                                        Ausübung
                                        oder Verteidigung von Rechtsansprüchen benötigen oder
                                        * Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
                                        * gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns
                                        bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren
                                        Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
                                        verlangen;
                                        * gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren.
                                        In
                                        der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen
                                        Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
                                        Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen
                                        Daten,
                                        bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie
                                        Widerruf
                                        erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung
                                        wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.
                                    </p>
                                    <h5> Widerspruchsrecht</h5>
                                    <p>
                                        Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
                                        berechtigten Interessen personenbezogene Daten wie oben erläutert verarbeiten,
                                        können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen.
                                        Erfolgt
                                        die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht
                                        jederzeit
                                        wie oben beschrieben ausüben. Soweit die Verarbeitung zu anderen Zwecken
                                        erfolgt,
                                        steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die sich aus
                                        Ihrer
                                        besonderen Situation ergeben, zu.
                                    </p><p>
                                    Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht
                                    weiter zu diesen Zwecken verarbeiten, es sei denn, wir können zwingende
                                    schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
                                    und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung
                                    oder Verteidigung von Rechtsansprüchen dient
                                    Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann
                                    werden wir Ihre personenbezogenen Daten nicht weiter zu diesem Zweck verarbeiten.
                                </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Datenschutz;