/*eslint-disable*/
import React from "react";

// reactstrap components
import {Container, Nav, NavItem, NavLink, Row} from "reactstrap";

// core components

function Footer() {
    return (
        <>
            <footer className="footer" data-background-color="black">
                <Container>
                    <Row>
                        <Nav className="mr-auto flex-row" id="ceva">
                            <NavItem>
                                <NavLink
                                    className="active"
                                    href="/Impressum"
                                >
                                    Impressum
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className="active"
                                    href="/datenschutz"
                                >
                                    Datenschutz
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <div className="copyright" id="copyright">
                            © {new Date().getFullYear()} {" "}

                            VEMO Logistik GmbH

                        </div>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default Footer;
