// React
import React from "react";
import Footer from "../../components/Footers/Footer";
import {ThemeProvider} from "@mui/material";
import ScrollTransparentNavbar from "../../components/Navbars/ScrollTransparentNavbar";
import NavProvider from "../../assets/context/NavContext";
import {createTheme} from "@mui/material/styles";
import {useLocation} from "react-router";

// Components
const ContentFrame = ({children}) => {
    const vemo = createTheme({
        palette: {
            primary: {
                main: '#2E6CA1',
            },
            secondary: {
                main: '#edf2ff',
            },
        },
        typography: {
            fontFamily: [
                'VarelaRound Regular',
            ].join(','),
            body1: {
                fontSize: "16.8px"
            }
        },
    });
    const {hash} = useLocation();
    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        document.body.scrollTop = 0;


        if (hash) {
            document.getElementById(hash.substring(1)).scrollIntoView({behavior: "smooth", block: "start"})
        }

        return function cleanup() {
            document.body.classList.remove("presentation-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <ThemeProvider theme={vemo}>
            <NavProvider>
                <div className="wrapper">
                    <ScrollTransparentNavbar/>
                    {children}
                    <Footer/>
                </div>
            </NavProvider>
        </ThemeProvider>
    );
}

export default ContentFrame;
