import React, {useContext} from 'react';
import {NavContext} from "../../assets/context/NavContext";
import {Button, NavItem, NavLink} from "reactstrap";
import {useLocation, useNavigate} from "react-router";

const NavJumpLink = ({navLinkId, scrollToId, isButton}) => {
    const {activeNavLinkId, setActiveNavLinkId} = useContext(NavContext);
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const handleClick = () => {
        if (pathname !== "") {
            navigate({pathname: "/", hash: `#${scrollToId}`});
        }
        setActiveNavLinkId(navLinkId);
    };

    return (
        isButton ? <NavItem>
                <Button
                    className="nav-link btn-yellow rounded-pill"
                    id={navLinkId}
                    onClick={handleClick}
                >
                    <p className="font-weight-bold">{navLinkId}</p>
                </Button>
            </NavItem> :
            <NavItem>
                <NavLink
                    id={navLinkId}
                    className={activeNavLinkId === navLinkId ? 'activeClass ' : ''}
                    onClick={handleClick}
                    tag="span"
                >
                    {navLinkId}
                </NavLink>
            </NavItem>
    );
};

export default NavJumpLink;
