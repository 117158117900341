import React from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {Collapse, Container, Nav, Navbar, NavbarBrand, Row,} from "reactstrap";
import {ReactComponent as VemoLogo} from 'assets/img/vemo-logo-blue.svg';
import {navLinks} from "./navLinks";
import NavJumpLink from "./NavJumpLink";

function ScrollTransparentNavbar() {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [navbarColor, setNavbarColor] = React.useState(
        (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
            ? " "
            : " "
    );
    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 499 ||
                document.body.scrollTop > 499
            ) {
                setNavbarColor("blur");
            } else if (
                document.documentElement.scrollTop < 500 ||
                document.body.scrollTop < 500
            ) {
                setNavbarColor("");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });


    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar className={`fixed-top shadow-sm rounded container mt-4 ${navbarColor}`} color="white"
                    expand="lg">
                <Container className={"p-lg-0"}>
                    <Row>
                        <div className="navbar-translate">
                            <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={scrollTop}>
                                <VemoLogo/>
                            </NavbarBrand>
                            <button
                                onClick={() => {
                                    document.documentElement.classList.toggle("nav-open");
                                    setCollapseOpen(!collapseOpen);
                                }}
                                aria-expanded={collapseOpen}
                                className="navbar-toggler"
                                aria-label="Mobile Navigation ein- / ausschalten"
                            >
                                <span aria-hidden="true">
                                <span className="navbar-toggler-bar top-bar"/>
                                <span className="navbar-toggler-bar middle-bar"/>
                                <span className="navbar-toggler-bar bottom-bar"/>
                                    </span>
                            </button>
                        </div>
                        <Collapse isOpen={collapseOpen} navbar>
                            <Nav className="ml-auto" id="ceva" navbar>
                                {navLinks.map(({navLinkId, target, isButton}, idx) => {
                                    return (

                                        <NavJumpLink key={idx} navLinkId={navLinkId} scrollToId={target}
                                                     isButton={isButton}/>
                                    );
                                })}
                            </Nav>
                        </Collapse>
                    </Row>
                </Container>
            </Navbar>
        </>
    );
}

export default ScrollTransparentNavbar;