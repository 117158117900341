import React from "react";
// javascript library that creates a parrallax effect
// reactstrap components
// core components
import {Button, Col, Container, Row} from "reactstrap";

// sections for this page

function DLSReferenz() {

    return (
        <>
            <div className="cd-section bg-primary spacing-top" id="BasicText">
                <div className="">
                    <div className="pb-5">
                        <Container>
                            <Row>
                                <Col
                                    className="mr-auto ml-auto bg-white rounded shadow pl-5 pr-5 py-4"
                                    md="9">
                                    <h1 className="h2 title">DLS Bäckerei</h1>
                                    <h2 className="h4">
                                        Der frühe Vogel fängt den Wurm: Um 5.30 Uhr sitzen wir für die DLS Bäckerei
                                        auf dem Fahrrad, wo uns das Gezwitscher im Morgengrauen belohnt.
                                    </h2>
                                    <p>
                                        Für den
                                        Bio-Bäcker beliefern wir jeden Morgen eine wachsende Anzahl von Bioläden,
                                        Cafés und Restaurants, die sich über die Produkte mit ausgezeichneter
                                        Qualität freuen: Fast ausschließlich lokale Zutaten verarbeitet das Team von
                                        David Lee Schlenker mit handwerklichem Geschick in seinen Broten, und sorgt
                                        dabei nicht nur für eine Stärkung der Region, sondern auch für unglaubliche
                                        Geschmackserlebnisse.
                                    </p>
                                    <p>
                                        Der Bäcker selbst ist in Hennef beheimatet, circa 25 Kilometer entfernt von
                                        Bonn und 40 Kilometer von Köln entfernt. In aller Früh verlädt er jeden
                                        Morgen seine Produkte in einen Sprinter und bringt diese nach Köln und Bonn
                                        – wo VEMO ins Spiel kommt. An unseren Standorten empfangen wir die fertig
                                        kommissionierten Produkte, verladen diese in unsere Lastenräder und machen
                                        uns sofort auf den Weg zu den Kunden der Bäckerei. Bei allen Stopps sammeln
                                        wir leere Brotkisten wieder ein und transportieren diese an unseren
                                        Standort. Am nächsten Morgen tauschen wir die leeren gegen frisch gefüllte
                                        Brotkisten und das Spiel beginnt von vorne.
                                    </p>
                                    <p>
                                        Einen vormals intern abgewickelten Prozess konnte die Bäckerei um VEMO als
                                        Dienstleister erweitern. Wo früher langwierige Touren und jede Menge Nerven
                                        in den Staus des Berufsverkehrs nötig waren, braucht es heute nur noch ein
                                        gutes Lastenrad mitsamt einem Fahrer, der gern Morgenluft schnuppert. Zwar
                                        waren die Frühaufsteher erst nicht leicht zu finden, jetzt haben wir aber
                                        eine schlagkräftige Truppe beieinander. Die DLS-Bäckerei kann durch uns ihre
                                        Anzahl an Sprintern reduzieren und benötigt weniger Personal. So einfach
                                        lässt sich die letzte Meile in Köln und Bonn nachhaltiger gestalten.
                                    </p>
                                    <p>
                                        Die Zusammenarbeit macht großen Spaß und findet immer auf Augenhöhe statt.
                                        Durch die enge Abstimmung haben wir die Integration nach weniger als drei
                                        Wochen abgeschlossen. Während die DLS nun auch die Auslieferungen nachhaltig
                                        gestalten kann, bleibt für die Kunden alles gleich: selber Ort, selbe Zeit
                                        für alle Lieferungen. Für sie ist nur die emissionsarme Lieferung mit den
                                        Lastenrädern neu – und die sorgt für echte Begeisterung.
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="text-center">
                                    <Button
                                        color="white"
                                        href="/#Referenzen-Section"
                                        className="rounded-pill text-center btn-yellow font-weight-bold"
                                    >
                                        zurück zur Übersicht
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DLSReferenz;