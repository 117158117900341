import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";

// Features Images
import {ReactComponent as FirstSecondDelivery} from 'assets/img/icons/first_second_mile.svg';
import {ReactComponent as FlexDelivery} from 'assets/img/icons/flex.svg';
import {ReactComponent as LastDelivery} from 'assets/img/icons/last_mile.svg';
import {ReactComponent as ConsultingDelivery} from 'assets/img/icons/beratung.svg';
import {useNav} from "../../assets/customHooks/useNav";

// core components

const Features = () => {

    const FeaturesRef = useNav('Features');
    return (
        <section ref={FeaturesRef} id='Leistungen-Section'>
            <div className="cd-section bg-primary text-white" id="features">
                <div className="features-6 d-flex justify-content-center">
                    <Container className="bg-light-1 rounded p-3 mx-sm-2 mx-2 mx-md-auto">
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="h2 title">Leistungen</h2>
                                <h3 className="h4 description">
                                    Für Deine Nachhaltige City-Logistik bieten wir Dir <br
                                    className="d-none d-lg-inline"/>
                                    unterschiedliche Leistungen an. Sprich uns gerne an!
                                </h3>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="5">
                                <div className="info info-horizontal">
                                    <div className="icon">
                                        <FirstSecondDelivery/>
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">First & Second Mile Delivery</h4>
                                        <p>
                                            Wir holen Eure Waren ab und sammeln diese für den späteren Versand.
                                            Ausschließlich mit Lastenrädern und mit individuellen Lösungen für Deine
                                            Logistik.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="5">
                                <div className="info info-horizontal">
                                    <div className="icon icon-primary">
                                        <LastDelivery/>
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Last Mile Delivery</h4>
                                        <p>
                                            Wir transportieren für Euch Waren zum Endkunden auf der letzten Meile.
                                            Pünktlich, zuverlässig und ohne Emissionen.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md="5">
                                <div className="info info-horizontal">
                                    <div className="icon">
                                        <FlexDelivery/>
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Flex Delivery</h4>
                                        <p>
                                            Wir finden für alle innerstädtischen Transportwege eine Lösung mit dem
                                            Fahrrad. Lass uns gemeinsam Dein individuelles Konzept finden und umsetzen.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md="5">
                                <div className="info info-horizontal">
                                    <div className="icon">
                                        <ConsultingDelivery/>
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Beratung</h4>
                                        <p>
                                            Ob Micro-Hub oder die Auswahl des richtigen Lastenfahrrads: Mit unserem
                                            Wissen und unserer Erfahrung helfen wir Deinem Unternehmen. Damit auch Du
                                            nachhaltige City-Logistik in die Praxis umsetzen kannst.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    );
}

export default Features;