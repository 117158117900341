import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardFooter, CardTitle, Col, Container, Row} from "reactstrap";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme
} from "@mui/material";
import * as PropTypes from "prop-types";
import styled from "@emotion/styled";
import {useNav} from "../../assets/customHooks/useNav";
import LazyLoad from 'react-lazyload';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    X
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function SupplyArea() {

    const SupplyAreaRef = useNav('SupplyArea');
    const [openKoeln, setOpenKoeln] = React.useState(false);

    const [openBonn, setOpenBonn] = React.useState(false);

    const handleBonnClickOpen = () => {
        setOpenBonn(true);
    };
    const handleKoelnClickOpen = () => {
        setOpenKoeln(true);
    };
    const handleClose = () => {
        setOpenBonn(false);
        setOpenKoeln(false);
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <section ref={SupplyAreaRef} id='Standorte-Section'>
            <div className="cd-section bg-primary bg-half-white text-white" id="features">
                <div className="supplyArea">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">Standorte</h2>
                                <h3 className="h4 description">
                                    Nachhaltige City-Logistik bieten wir aktuell in Köln und Bonn an.
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="10" lg="10">
                                <Row className="justify-content-around pt-5">
                                    <Col md="6" lg="5">
                                        <Card className="card-plain bg-white shadow">
                                            <div className="card-image rounded shadow">
                                                <Box onClick={handleBonnClickOpen} className={"pe-auto"}
                                                     sx={{cursor: "pointer"}}>
                                                    <LazyLoad height={275}>
                                                        <img
                                                            alt="Region Bonn"
                                                            className="img"
                                                            src={require("assets/img/Bonn_blau.png")}
                                                        />
                                                    </LazyLoad>
                                                </Box>
                                            </div>
                                            <CardBody>
                                                <CardTitle tag="h4"
                                                           className="h5 text-center category text-primary font-weight-bold">
                                                    Region Bonn
                                                </CardTitle>
                                                <CardFooter className="text-center">
                                                    <Button className="rounded-pill btn btn-primary"
                                                            onClick={handleBonnClickOpen}>Mehr Erfahren</Button>
                                                    <BootstrapDialog
                                                        onClose={handleClose}
                                                        aria-labelledby="customized-dialog-title"
                                                        open={openBonn}
                                                        fullScreen={fullScreen}
                                                        maxWidth="md"
                                                    >
                                                        <DialogContent dividers className={"p-0 mt-0 border-top-0"}>
                                                            <Container>
                                                                <Row>
                                                                    <Col className={"pl-0 p-0 col-12 col-md-6"}>
                                                                        <img
                                                                            alt="..."
                                                                            className="img-fluid"
                                                                            src={require("assets/img/Bonn_blau.png")}
                                                                        />
                                                                    </Col>
                                                                    <Col className="">
                                                                        <h2 className="title h4 my-1">Region Bonn</h2>
                                                                        <h4 className="h5 mt-2 mb-3">
                                                                            Wir liefern in folgenden PLZ bereichen.
                                                                        </h4>
                                                                        <Row>
                                                                            <Col xs={4} md={2}>
                                                                                <ul className="list-unstyled">
                                                                                    <li>53111</li>
                                                                                    <li>53113</li>
                                                                                    <li>53115</li>
                                                                                    <li>53117</li>
                                                                                    <li>53117</li>

                                                                                </ul>
                                                                            </Col>
                                                                            <Col xs={4} md={2}>
                                                                                <ul className="list-unstyled">
                                                                                    <li>53119</li>
                                                                                    <li>53121</li>
                                                                                    <li>53123</li>
                                                                                    <li>53127</li>
                                                                                    <li>53129</li>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col xs={4} md={2}>
                                                                                <ul className="list-unstyled">
                                                                                    <li>53173</li>
                                                                                    <li>53175</li>
                                                                                    <li>53177</li>
                                                                                    <li>53179</li>
                                                                                    <li>53225</li>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col xs={4} md={2}>
                                                                                <ul className="list-unstyled">
                                                                                    <li>53227</li>
                                                                                    <li>53229</li>
                                                                                    <li>53347</li>
                                                                                </ul>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button
                                                                className="rounded-pill btn btn-primary"
                                                                onClick={handleClose}>schließen</Button>
                                                        </DialogActions>
                                                    </BootstrapDialog>
                                                </CardFooter>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="6" lg="5">
                                        <Card className="card-plain bg-white shadow">
                                            <div className="card-image rounded shadow">
                                                <Box onClick={handleKoelnClickOpen} className={"pe-auto"}
                                                     sx={{cursor: "pointer"}}>
                                                    <LazyLoad height={275}>
                                                        <img
                                                            alt="Region Köln"
                                                            className="img"
                                                            src={require("assets/img/koeln_blau.png")}
                                                        />
                                                    </LazyLoad>
                                                </Box>
                                            </div>
                                            <CardBody>
                                                <CardTitle tag="h4"
                                                           className="h5 text-center category text-primary font-weight-bold">
                                                    Region Köln
                                                </CardTitle>
                                                <CardFooter className="text-center">
                                                    <Button className="rounded-pill btn btn-primary"
                                                            onClick={handleKoelnClickOpen}>Mehr Erfahren</Button>
                                                </CardFooter>
                                                <BootstrapDialog
                                                    onClose={handleClose}
                                                    aria-labelledby="customized-dialog-title"
                                                    open={openKoeln}
                                                    fullScreen={fullScreen}
                                                    maxWidth="md"
                                                >
                                                    <DialogContent dividers className={"p-0 mt-0 border-top-0"}>
                                                        <Container>
                                                            <Row>
                                                                <Col className={"pl-0 p-0 col-12 col-md-6"}>
                                                                    <img
                                                                        alt="..."
                                                                        className="img-fluid"
                                                                        src={require("assets/img/koeln_blau.png")}
                                                                    />
                                                                </Col>
                                                                <Col className="">
                                                                    <h2 className="title h4 my-1">Region Köln</h2>
                                                                    <h4 className="h5 mt-2 mb-3">
                                                                        Wir liefern in folgenden PLZ bereichen.
                                                                    </h4>
                                                                    <Row>
                                                                        <Col xs={4} md={2}>
                                                                            <ul className="list-unstyled">
                                                                                <li>50667</li>
                                                                                <li>50668</li>
                                                                                <li>50670</li>
                                                                                <li>50672</li>
                                                                                <li>50674</li>
                                                                                <li>50676</li>
                                                                            </ul>
                                                                        </Col>
                                                                        <Col xs={4} md={2}>
                                                                            <ul className="list-unstyled">
                                                                                <li>50677</li>
                                                                                <li>50678</li>
                                                                                <li>50679</li>
                                                                                <li>50733</li>
                                                                                <li>50735</li>
                                                                                <li>50739</li>
                                                                            </ul>
                                                                        </Col>
                                                                        <Col xs={4} md={2}>
                                                                            <ul className="list-unstyled">
                                                                                <li>50823</li>
                                                                                <li>50825</li>
                                                                                <li>50827</li>
                                                                                <li>50829</li>
                                                                                <li>50931</li>
                                                                                <li>50933</li>
                                                                            </ul>
                                                                        </Col>
                                                                        <Col xs={4} md={2}>
                                                                            <ul className="list-unstyled">
                                                                                <li>50935</li>
                                                                                <li>50937</li>
                                                                                <li>50939</li>
                                                                                <li>50967</li>
                                                                                <li>50968</li>
                                                                                <li>51063</li>
                                                                            </ul>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            className="rounded-pill btn btn-primary"
                                                            onClick={handleClose}>schließen</Button>
                                                    </DialogActions>
                                                </BootstrapDialog>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    );
}

export default SupplyArea;