import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardFooter, CardTitle, Col, Container, NavLink, Row} from "reactstrap";
import {useNav} from "../../assets/customHooks/useNav";
import LazyLoad from 'react-lazyload';

function References() {
    const ReferencesRef = useNav('Referenzen');
    return (
        <section ref={ReferencesRef} id='Referenzen-Section'>
            <div className="section section-plain-cards bg-primary">
                <Container>
                    <Row>
                        <Col className="mr-auto ml-auto text-center text-white" md="8">
                            <h2 className="title">Referenzen</h2>
                        </Col>
                    </Row>
                    <Row className="mb-4 align-items-end">
                        <Col xs="6" lg="3" md="6">
                            <Card className="card-plain">
                                <NavLink href="/referenz/DLS-Baeckerei" className="p-0">
                                    <div className="card-image text-center">
                                        <noscript>
                                            <img
                                                alt="DLS Bäckerei"
                                                className="img w-25"
                                                src="assets/img/referenzen/dls.png"
                                            />
                                        </noscript>
                                        <LazyLoad height={125}>
                                            <img
                                                alt="DLS Bäckerei"
                                                className="img w-25"
                                                src={require("assets/img/referenzen/dls.png")}
                                            />
                                        </LazyLoad>
                                    </div>
                                    <CardBody className="text-center">
                                        <CardTitle tag="h3" className="h5 text-white">
                                            DLS Bäckerei
                                        </CardTitle>
                                        <CardFooter>
                                            <Button
                                                color="neutral"
                                                className="rounded-pill"
                                            >
                                                Mehr Erfahren
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </NavLink>
                            </Card>
                        </Col>
                        <Col xs="6" lg="3" md="6">
                            <Card className="card-blog card-plain">
                                <NavLink href="/referenz/Bioladen-Momo" className="p-0">
                                    <div className="card-image text-center">
                                        <LazyLoad height={125}>
                                            <img
                                                alt="Bioladen Momo"
                                                className="img w-75"
                                                src={
                                                    require("assets/img/referenzen/momo.png")
                                                }
                                            />
                                        </LazyLoad>
                                    </div>
                                    <CardBody className="text-center">
                                        <CardTitle tag="h3" className="h5 text-white">
                                            Bioladen Momo
                                        </CardTitle>
                                        <CardFooter>
                                            <Button
                                                color="neutral"
                                                className="rounded-pill"
                                            >
                                                Mehr Erfahren
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </NavLink>
                            </Card>
                        </Col>
                        <Col xs="6" lg="3" md="6">
                            <Card className=" card-plain">
                                <NavLink href="/referenz/Memo" className="p-0">
                                    <div className="card-image text-center">
                                        <LazyLoad height={125}>
                                            <img
                                                alt="Memo AG"
                                                className="img w-50"
                                                src={require("assets/img/referenzen/memo.png")}
                                            />
                                        </LazyLoad>
                                    </div>
                                    <CardBody className="text-center">
                                        <CardTitle tag="h3" className="h5 text-white">
                                            Memo AG
                                        </CardTitle>
                                        <CardFooter>
                                            <Button
                                                color="neutral"

                                                className="rounded-pill"
                                            >
                                                Mehr Erfahren
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </NavLink>
                            </Card>
                        </Col>
                        <Col xs="6" lg="3" md="6">
                            <Card className="card-blog card-plain">
                                <NavLink href="/referenz/Ulmenhof" className="p-0">
                                    <div className="card-image text-center">
                                        <LazyLoad height={125}>
                                            <img
                                                alt="Ulmenhof"
                                                className="img w-50"
                                                src={
                                                    require("assets/img/referenzen/Ulmenhof.png")
                                                }
                                            />
                                        </LazyLoad>
                                    </div>
                                    <CardBody className="text-center">
                                        <CardTitle tag="h3" className="h5 text-white">
                                            Ulmenhof
                                        </CardTitle>
                                        <CardFooter>
                                            <Button
                                                color="neutral"
                                                className="rounded-pill"
                                            >
                                                Mehr Erfahren
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </NavLink>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default References;