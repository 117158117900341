import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";
import {ReactComponent as NachhaltigIcon} from 'assets/img/icons/nachhaltig.svg';
import {ReactComponent as TransparentIcon} from 'assets/img/icons/transparenz.svg';
import {ReactComponent as SozialIcon} from 'assets/img/icons/sozial.svg';
import {useNav} from "../../assets/customHooks/useNav";

function Vision() {
    const VisionRef = useNav('VisionHeader');
    return (
        <section ref={VisionRef} id='Vision-Section'>
            <div className="cd-section bg-white" id="features">
                <div className="about-description">
                    <div className="features-3">
                        <Container>
                            <Row>
                                <Col className="mr-auto ml-auto" md="10" lg="8">
                                    <h2 className="title">Vision</h2>
                                    <h3 className="h4">
                                        Wir haben VEMO gegründet, um Stadtverkehr neu zu denken. Wir suchen kreative
                                        Logistiklösungen und ersetzen Transporter durch emissionsärmere Alternativen.
                                        Dabei möchten wir so sozial, nachhaltig und transparent wie möglich sein. Weil
                                        die Stadt der Zukunft uns alle angeht.
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="d-none d-sm-flex justify-content-center mt-md-3">
                                <Col xs="4" md="3">
                                    <div className="info info-hover pt-4 pb-2">
                                        <div className="icon icon-primary icon-circle">
                                            <NachhaltigIcon/>
                                        </div>
                                        <h4 className="info-title">Nachhaltig</h4>
                                    </div>
                                </Col>
                                <Col xs="4" md="3">
                                    <div className="info info-hover pt-4 pb-2">
                                        <div className="icon icon-primary icon-circle w-50">
                                            <TransparentIcon/>
                                        </div>
                                        <h4 className="info-title">Transparent</h4>
                                    </div>
                                </Col>
                                <Col xs="4" md="3">
                                    <div className="info info-hover pt-4 pb-2">
                                        <div className="icon icon-primary icon-circle">
                                            <SozialIcon/>
                                        </div>
                                        <h4 className="info-title">Sozial</h4>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Vision;